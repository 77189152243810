import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css";
import logo from "../images/main-logo.png";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < lastScrollY) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header
      className={`header-Selmore ${isVisible ? "visible" : "hidden"}`}
      onClick={scrollToTop}
    >
      <div className="header-content">
        <Link to="/" className="logo-main-selmore" onClick={scrollToTop}>
          <img src={logo} alt="Selmore Logo" />
        </Link>

        <button
          className="mobile-menu-toggle"
          onClick={toggleMobileMenu}
          aria-label="Toggle mobile menu"
        >
          {isMobileMenuOpen ? "✕" : "☰"}
        </button>

        <nav className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
          <Link to="/" onClick={toggleMobileMenu}>
            Home
          </Link>
          <Link to="/services" onClick={toggleMobileMenu}>
            Services
          </Link>
          <Link to="/about-us" onClick={toggleMobileMenu}>
            About Us
          </Link>
          <Link to="/explore" onClick={toggleMobileMenu}>
            Explore Billboards
          </Link>
          <Link to="/contact-us" onClick={toggleMobileMenu}>
            Contact Us
          </Link>
          <Link to="/faqs" onClick={toggleMobileMenu}>
            FAQs
          </Link>

          <Link
            to="/book-now"
            className="book-now-link"
            onClick={toggleMobileMenu}
          >
            <button className="book-nowbtn2">Book Now</button>
          </Link>
        </nav>

        <Link
          to="/book-now"
          className="book-now-link"
          onClick={toggleMobileMenu}
        >
          <button className="book-nowbtn">Book Now</button>
        </Link>
      </div>
    </header>
  );
}
